import { useCallback, useState, useEffect, useContext, useRef } from "react";
import { GuideContext, DialogContext } from "context";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { Pane, toaster } from "evergreen-ui";
import { onSearchPoi, onSearchCoords } from "api-request";
import { UnderlinedLabel, HowTo } from "components/Base";
import { PoiMarker, MapSearch } from "./components";
import { darkMode } from "./utils";
import { onParseUrl, parseCoords } from "utils";
import { onInit, onShare, onPoi } from "api-request";

const ZOOM_LEVEL = 13;

export const ProjectMap = ({ loading, setLoading }) => {
  const { dialog, setDialog } = useContext(DialogContext);
  const { guide, setGuide, setShowGuide, setUserLocation } =
    useContext(GuideContext);
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [zoom, setZoom] = useState(14);
  const [hidePin, setHidePin] = useState(false);

  const [isLoadComplete, setIsLoadComplete] = useState(false);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  // **************************************
  // GET MAP
  // **************************************
  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);

    setTimeout(() => {
      map.setZoom(ZOOM_LEVEL);
      setIsLoadComplete(true);
      if (!center) {
        setCenter(parseCoords([0, 0]));
      }

      // Add click event listener to the map
      window.google.maps.event.addListener(map, "click", (e) => {
        if (map.getZoom() <= 10) {
          const zoomCenter = [e.latLng.lat(), e.latLng.lng()];
          setCenter(parseCoords(zoomCenter));
          setHidePin(true);
        }
      });

      // Add listener for zoom events
      window.google.maps.event.addListener(map, "zoom_changed", () => {
        setZoom(map.getZoom());
      });

      // add listener to read just zoom level when center changes
      window.google.maps.event.addListener(map, "center_changed", () => {
        map.setZoom(ZOOM_LEVEL);
        setZoom(ZOOM_LEVEL);
      });
    }, 100);
  }, []);

  // on close map
  const onUnmount = useCallback(function callback(map) {
    map = null;
  }, []);

  // handles map poi click
  const handlePoiClick = async (e) => {
    try {
      e.stop();
      if (loading || zoom <= 10) return;
      setLoading(true);
      const poi = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      };

      // begin tour
      const tour =
        "placeId" in e
          ? await onSearchPoi(e.placeId)
          : await onSearchCoords(poi);

      if (tour) {
        const confirm = window.confirm(
          `You are about to begin a tour of ${tour?.label || "..." }?`
        );
        if (confirm) {
          setHidePin(false);
          setGuide(tour);
          setCenter(parseCoords(tour?.coordinates));
          setShowGuide(true);
          // get tour
          onPoi(tour, setGuide);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toaster.danger("Something went wrong.", {
        id: "xyz",
        description: "Please refresh and try again.",
        duration: 10,
      });
    }
  };

  // **************************************
  // SAY HELLO
  // **************************************
  const sayHello = () => {
    // const hello = localStorage.getItem("hello");

    // if (!hello) {
    //   localStorage.setItem("hello", new Date().getMilliseconds());
    // }

    // if (!hello) {
    //   if (dialog.isShown) return;

    //   return setDialog((prev) => ({
    //     ...prev,
    //     refresh: false,
    //     isShown: true,
    //     title: (
    //       <UnderlinedLabel>
    //         Welcome to {process.env.REACT_APP_BRAND}
    //       </UnderlinedLabel>
    //     ),
    //     children: <HowTo getUserLocation={getUserLocation} />,
    //   }));
    // }

    // ask for user permissions for location services
    return getUserLocation();
  };

  // **************************************
  // GET USER LOCATION
  // **************************************
  const getUserLocation = useCallback(async () => {
    const options = {
      enableHighAccuracy: true,
      timeout: 20000,
      maximumAge: 0,
    };

    const showPosition = async (position) => {
      const res = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
      // set the users initial location coordinates
      setUserLocation([res.lat, res.lng]);

      // Check if this is a shared location
      const share = onParseUrl("share")
      if (share) {
        return await onShare(share, setGuide, setShowGuide, setLoading);
      }

      const data = await onInit(res);
      setLoading(false);

      if (data) {
        setGuide(data);
      }
    };

    const error = async (e) => {
      // Check if this is a shared location
      const share = onParseUrl("share")
      if (share) {
        return await onShare(share, setGuide, setShowGuide, setLoading);
      }

      setLoading(false);
      setHidePin(true);

      setTimeout(() => {
        const sanFrancisco = [37.77742923109572, -122.42025504967398];
        setCenter(parseCoords(sanFrancisco));
      }, [100]);

      toaster.danger("Location Error", {
        id: "xyz",
        description:
          "Please ensure location services are enabled for an enhanced experience.",
        duration: 10,
      });
    };

    // start location services
    if (navigator.geolocation) {
      return navigator.geolocation.getCurrentPosition(
        showPosition,
        error,
        options
      );
    } else {
      return error();
    }
  }, [setGuide, setUserLocation]);

  // initial load
  const isMountedRef = useRef(false);
  useEffect(() => {
    if (!isMountedRef.current) {
      isMountedRef.current = true;
      sayHello();
    }
  }, [sayHello]);

  // center map when selected tour changes
  useEffect(() => {
    if (guide?.coordinates) {
      setCenter(parseCoords(guide.coordinates));
    }
  }, [guide]);

  return isLoaded ? (
    <Pane width="100%" height="110vh">
      <GoogleMap
        mapContainerStyle={{
          left: "-1%",
          width: "102%",
          height: "100%",
        }}
        onClick={(e) => handlePoiClick(e)}
        center={center}
        zoom={2}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{
          maxZoom: 17,
          minZoom: 3,
          disableDefaultUI: true,
          gestureHandling: "greedy",
          styles: darkMode,
        }}
      >
        <PoiMarker
          hidePin={hidePin}
          isLoadComplete={isLoadComplete}
          center={center}
        />
      </GoogleMap>
      <MapSearch
        setHidePin={setHidePin}
        setCenter={setCenter}
        loading={loading}
        setLoading={setLoading}
      />
    </Pane>
  ) : (
    <></>
  );
};
