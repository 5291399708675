import { useContext } from "react";
import {
  Pane,
  Heading,
  MobilePhoneIcon,
  FlashIcon as HelpIcon,
  LinkIcon as ShareIcon
} from "evergreen-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faYoutube, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { DialogContext } from "context";
import { HowTo, MenuLabel, Pwa, UnderlinedLabel } from "components/Base";
import { onShare, isPwa } from "utils";
import { constants } from "constants";

export const MenuList = () => {
  const { host } = constants;
  const { setDialog } = useContext(DialogContext);

  return (
    <Pane display="flex" flexDirection="column" gap={30}>
       {/* how to */}
       <MenuLabel
        cursor="pointer"
        onClick={() => {
          return setDialog((prev) => ({
            ...prev,
            isShown: true,
            title: (
              <UnderlinedLabel>
                How to {process.env.REACT_APP_BRAND}
              </UnderlinedLabel>
            ),
            children: <HowTo />,
          }));
        }}
      >
        <HelpIcon /> <Heading>- How To</Heading>
      </MenuLabel>
      {/* mobile app */}
      {!isPwa ? (
        <MenuLabel
          cursor="pointer"
          onClick={() => {
            return setDialog((prev) => ({
              ...prev,
              isShown: true,
              title: <></>,
              children: <Pwa />,
            }));
          }}
        >
          <MobilePhoneIcon />
          <Heading>- Mobile App</Heading>
        </MenuLabel>
      ) : null}
      {/* share */}
      <MenuLabel cursor="pointer" onClick={() => onShare(`${host}`)}>
        <ShareIcon />
        <Heading>- Share</Heading>
      </MenuLabel>
     
      {/* you tube */}
      {/* <MenuLabel
        cursor="pointer"
        onClick={() =>
          window.open("https://www.youtube.com/channel/UC9QwDChLd2RhT0yqxgaeR5g", "_blank")
        }
      >
        <FontAwesomeIcon size="1x" color="red" icon={faYoutube} />
        <Heading>- YouTube</Heading>
      </MenuLabel> */}
      {/* twitter */}
      <MenuLabel
        cursor="pointer"
        onClick={() => window.open("https://twitter.com/Togogo_App", "_blank")}
      >
        <FontAwesomeIcon size="1x" icon={faXTwitter} />
        <Heading>- X (twitter)</Heading>
      </MenuLabel>
    </Pane>
  );
};
