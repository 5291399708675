import { Marker } from "@react-google-maps/api";
import { useContext } from "react";
import { GuideContext } from "context";
import { onPoi } from "api-request";
import { windowRedirect } from "utils";

export const PoiMarker = ({ hidePin, isLoadComplete, center }) => {
  const { guide, setGuide, setShowGuide } = useContext(GuideContext);

  return !hidePin && isLoadComplete ? (
    <Marker
      position={center}
      animation="DROP"
      onClick={async () => {
        if (guide) {
          const confirm = window.confirm(
            `You are about to begin a tour of ${guide?.label || "..."}?`
          );
          
          // redirect user to sign in if they do not have an account
          if (confirm) {
            if (!localStorage.getItem("session")) {
              return windowRedirect("auth");
            }
        
            // initilize poi tour
            setGuide((prev) => ({
              ...prev,
              message: "",
            }));
            onPoi(guide, setGuide);
            setShowGuide(true);
          }
        }
      }}
    />
  ) : null;
};
